@import "rsuite/dist/rsuite.css";
.zv-curso-body-header{
    display: flex;
    gap:8px
}
.zv-fileUploader
{
    display: grid;
    gap: 16px;
    
   
}
.rs-uploader-picture .rs-uploader-file-item {
       
    width: 200px !important;
    height: 200px !important;
     
}
.rs-uploader-picture .rs-uploader-file-item-preview {
    height: 200px !important;
}  

.dropzone {

    border: 2px dashed rgba(0,0,0,.3) !important;
    margin: 5px 0px 10px 0px !important;
}


.dropzone .dz-preview .dz-progress {
    /* z-index: 1000; */
    top: 70% !important;
    background: rgba(255,255,255,.9) !important;
    border: none !important;
}
.layout-sidebar {
    position: fixed;
    height: calc(100vh - 72px);
    width: 246px;
    z-index: 999;
    overflow-y: auto;
    -webkit-user-select: none;
    user-select: none;
    top: 72px;
    //left: 2rem;
    transition: left 0.2s, -webkit-transform 0.2s;
    transition: transform 0.2s, left 0.2s;
    transition: transform 0.2s, left 0.2s, -webkit-transform 0.2s;
    background-color: #b5b5b5 !important;
    padding: 1.5rem 0px 1.5rem 0px;
    box-shadow: 0px 3px 5px rgb(0 0 0 / 2%), 0px 0px 2px rgb(0 0 0 / 5%), 0px 1px 4px rgb(0 0 0 / 8%);
}
.layout-menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.layout-menu li ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.layout-menu li a.router-link-exact-active {
    font-weight: 700;
    color: #000000;
}
.menuitem-toggle-icon
{
    position: absolute;
    right: 10px !important;
}
.active-menuitem
{
    font-weight: 700;
}
.layout-menu li ul ul {
    padding-left: 1rem;
    background: #8c8c8c;
}
.layout-menu li a {
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #000000;
    transition: color 0.2s;
    border-radius: 12px;
    padding: 0.75rem 1rem;
    transition: background-color 0.15s;
}
.layout-topbar-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #ffffff !important;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    transition: background-color 0.2s;
}
@media (min-width: 992px)
{
    .layout-wrapper.layout-static.layout-static-sidebar-inactive .layout-sidebar {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        left: 0;
    }
}

.layout-menu li.layout-menuitem-category:first-child {
    margin-top: 0;
}

.layout-menu li.layout-menuitem-category {
    margin-top: 0.75rem;
}

.layout-menu li .layout-menuitem-root-text {
    text-transform: uppercase;
    color: var(--surface-900);
    font-weight: 600;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
}
.layout-menu li a span {
    margin-left: 0.5rem;
}

.p-ink {
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.p-ink-active{
    animation: ripple 0s linear !important;
}
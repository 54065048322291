*{
  //font-family: "Poppins";
  font-family: poppins, sans-serif;
  }

::placeholder { 
  color: #B5B5B5;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.layout-main
{ 
color: #000000;
}

button{
  border-color: transparent !important;
}

.zv-slider-left
{
  background-color: transparent;    border: none !important;
  box-shadow: none !important;
}

@media (min-width: 992px)
{
  .layout-wrapper.layout-static .layout-main-container {
    margin-left: 250px;
}
} 

@media (min-width: 992px)
{
  .layout-wrapper.layout-static.layout-static-sidebar-inactive .layout-main-container {
    margin-left: 0;
    padding-left: 2rem;
  }
} 

.layout-main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  padding: 72px 2rem 2rem 4rem;
  transition: margin-left 0.2s;
  margin-top: 0;
}
.header-titulo{
  font-size: 32px;
  font-weight: bold;
  line-height: 32px;
  font-style: normal;
  color: #000000;
}

.header-subTitulo{
  font-size: 24px;
  font-weight: bold;
  line-height: 24px;
  font-style: normal;
  color: #000000;
}

.label-form{
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  font-style: normal;
  color: #000000;
}
.datatable-accion
{
    display: flex;
    gap:12px;
    align-items: center;
}
.accion-eliminar
{
    display: flex;
    align-items: center;
    justify-content: center;
    background:#DD4B39;
    //width: 20%;
    padding: 6px;
    cursor: pointer;
    span{
      display: flex;
      align-items: center;
    }
}

.accion-editar
{
    display: flex;
    align-items: center;
    justify-content: center;
    background:#404BD9;
    //width: 20%;
    padding: 6px;
    cursor: pointer;
    span{
      display: flex;
      align-items: center;
    }
    
}

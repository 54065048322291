.contentLogin {
    
    display: flex;
    flex-direction: column;
    height: 560px;
    width: 470px;
    // border-radius: 16px !important; 
    padding: 0;
    /* padding: 56px !important; */
    // padding-top: 56px;
    padding-bottom: 56px;
    background: #FFFFFF;
  }
  
  .contentLogin p.error {
    margin: 0 0 10px 10px;
    text-align: left;
    font-size: 10px;
    color: red;
  }
  .peva-button-login
  {
    width: 100%;
    border-radius: 16px;
    background: #463347;
    
  }

  .peva-request-pass
  {
    width: 100%;
    margin-top :14px;
    text-align: center;
    color:#463347;
    text-decoration: underline;
  }


.form-login
{
  margin-left: 42px;
  margin-right: 42px;
 
  input
  {
      color: black !important;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      padding-left: 14px;
  }
  
}
.form-img{
  margin-left: 52px;
  margin-right: 52px;
  margin-top: 72px;
  margin-bottom: 22px;
}
.form-footer
{
  display: flex;
  align-items: center;
  justify-content: center;
  button{
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
  }
}
.p-titulo-login
{
  text-align: center;
  margin-bottom: 12px;
  font-weight: 700;
    font-size: 18px;
    line-height: 21px;
}
@media (max-width: 991px) {
  .contentLogin
  {
    top: 190px;
    left: 5%;
    width: 90%!important;
    height: 60%!important;
    padding-top: 32px;
    padding-bottom: 32px;
    img
    {
      width: 196px;
    }
  }
  .form-login
  {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 32px;
  }
}
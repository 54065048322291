.datatable-accion-editar
{
    display: flex;
    align-items: center;
    justify-content: center;
    background:#404BD9;
    width: 20%;
    padding: 4px;
    cursor: pointer;
    
}
.zv-editarUsuario-footer
{
    display: inline-flex;
    gap: 16px;
}